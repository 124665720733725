<template>
  <div class="my-4">
    <b-container fluid>
      <b-row class="mb-4">
        <b-col>
              <pdf-view :path="path" :fileName="name"/>
        </b-col>
        <b-col>
          hello
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PdfView from '@/components/PdfView.vue'

export default {
  name: 'RegDecompWorkspace',
  components: {
    PdfView
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
  },
  data () {
    return {
      name: 'http://localhost:8080/pdf/ss319.pdf',
      path: '/pdfjs/web/viewer.html'
    }
  },
  methods: {
  }
}
</script>
